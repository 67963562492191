import { useState, useEffect } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import NavLink from "./nav";
import { useDispatch, useSelector } from "react-redux";
import { SetOpenMenu } from "../../redux/actions/setupUser.actions";

export default function HeaderOtherPage() {
    const [sidebarWidth, setSidebarWidth] = useState(undefined);
    const dispatch = useDispatch();
    const openMenu = useSelector((state) => state.setupUser.openMenu);
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    useEffect(() => {
        const sidebarEl = document
            .querySelector("header")
            .getBoundingClientRect();
        setSidebarWidth(sidebarEl.width);
    }, []);
    const detectDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        setSidebarWidth(window.innerWidth);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const header = document.querySelector("header");
        const nav = document.querySelector("nav");
        const navicon = document.querySelector(".nav-icon");

        header.classList.add("is-sticky");
        nav.classList.add("opacity-100");
        navicon.classList.add("display-nav-icon");
    }, []);

    useEffect(() => {
        window.addEventListener("resize", detectDimension);
        return () => {
            window.removeEventListener("resize", detectDimension);
        };
    }, [screenSize]);

    return (
        <header>
            <div
                className="nav-icon"
                onClick={() => dispatch(SetOpenMenu(!openMenu))}
            >
                <TfiMenuAlt />
                <span>Menu</span>
            </div>
            <div style={{ width: sidebarWidth }} className="navbar-brand">
                <span className="left">Les Trésors </span>
                <span className="right">de l'Harmonie</span>
            </div>
            <NavLink />
        </header>
    );
}
