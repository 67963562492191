import { useEffect } from "react";
import pendule from "../assets/img/pendulum-4868645_640.jpg";

export default function PagePendules() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <section className="card-homepage_green">
                <div className="div_header-card-content">
                    <h1>Voyance & Pendule </h1>

                    <h2>Découvrez l'énergie qui vous entoure</h2>
                    <p>
                        Plongez dans le monde de la radiesthésie et explorez les
                        possibilités infinies de la divination par le pendule.
                        <br />
                        La voyance par le pendule est un héritage ancestral,
                        utilisé par des visionnaires et des médiums à travers
                        les âges.{" "}
                    </p>
                    <p className="text-center">
                        Aujourd'hui, Cette sagesse à votre portée.{" "}
                    </p>
                    <p>
                        Ma collection de pendules est soigneusement sélectionnée
                        car l'énergie de chaque pendule est unique ayant des
                        bienfaits différents en fonction de sa demande.
                    </p>
                    <p>
                        {" "}
                        Obtenez des réponses précises à vos questions, éclairez
                        votre chemin et prenez des décisions grâce à une
                        compréhension approfondie de votre avenir.
                    </p>
                </div>
                <div className="div_header-card-left">
                    <img src={pendule} alt="Pendule ésothérique" />
                </div>
            </section>
            <section className="card-homepage_white">
                <div className="div_header-card-content">
                    Cet art divinatoire reste strictement personnel et ne sera
                    pas utiliser à des fins d'informations/Vie Privée sur Autrui
                </div>
            </section>
        </main>
    );
}
