import { Link } from "react-router-dom";
import carte_visite_les_tresors_de_lharmonie from "../../assets/img/carte_visite_les_tresors_de_lharmonie.jpg";

export default function CardHomepageHumain() {
    return (
        <>
            <section className="card-homepage-humain">
                <h2>Soins pour les Humains </h2>
                <hr />
                <h3>Domaines d'application Corporel :</h3>
                <ul>
                    <li>
                        <span>Douleurs musculaires et articulaires : </span>
                        <br />
                        Soulager vos douleurs liées à des tensions musculaires,
                        des entorses, des lumbagos, ou d'autres problèmes
                        similaires.
                    </li>

                    <li>
                        <span> Problèmes circulatoires :</span> <br />
                        La reboutologie peut favoriser une meilleure circulation
                        sanguine ou favoriser le drainage lymphatique.
                    </li>
                    <li>
                        <span> Problèmes de peau :</span> <br />
                        Brûlures, eczéma, psoriasis, Zona, acné, coup de soleil,
                        etc. peuvent être soulagés et réduits grâce au
                        magnétisme.
                    </li>
                </ul>
                <h3>Domaines d'application Psychique :</h3>
                <ul>
                    <li>
                        <span>Stress :</span> <br />
                        Soulager le stress, l'anxiété, les angoisses, les
                        troubles du sommeil, les dépressions, etc.
                    </li>
                    <li>
                        <span>Problèmes/Blocages émotionnels :</span> <br />
                        Surmonter vos problèmes émotionnels, comme des chocs,
                        des deuils, des traumatismes, etc. La pratique de la TLE
                        (technique de libération émotionnel) est conseillée.
                        D'autre technique énergétique peuvent agir également sur
                        l'énergie vitale du corps, contribuant ainsi à rétablir
                        l'harmonie générale.
                    </li>
                </ul>
            </section>{" "}
            <div className="div_header">
                <Link to="/contact-gps">
                    <div className="div_header-card">
                        <img
                            src={carte_visite_les_tresors_de_lharmonie}
                            alt="Carte de Visite"
                        />
                    </div>
                </Link>
            </div>
        </>
    );
}
