const initialState = { openMenu: false };

export default function setupUser(state = initialState, action) {
    switch (action.type) {
        case "SET_THEME_COLOR":
            return {
                ...state,
                theme: action.theme,
            };
        case "OPEN_MENU":
            return {
                ...state,
                openMenu: action.openMenu,
            };
        default:
            return state;
    }
}
