import { useState, useEffect } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { MdClose } from "react-icons/md";

import { SetOpenMenu } from "../../redux/actions/setupUser.actions";
import { useDispatch, useSelector } from "react-redux";
import NavLink from "./nav";

export default function Header() {
    const [sidebarWidth, setSidebarWidth] = useState(undefined);
    const [sidebarTop, setSidebarTop] = useState(undefined);
    const dispatch = useDispatch();
    const openMenu = useSelector((state) => state.setupUser.openMenu);
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const detectDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        setSidebarWidth(window.innerWidth);
    };
    useEffect(() => {
        const sidebarEl = document
            .querySelector("header")
            .getBoundingClientRect();
        setSidebarWidth(sidebarEl.width);
        setSidebarTop(sidebarEl.top);
    }, []);

    useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarTop]);
    useEffect(() => {
        window.addEventListener("resize", detectDimension);
        return () => {
            window.removeEventListener("resize", detectDimension);
        };
    }, [screenSize]);
    const isSticky = () => {
        const header = document.querySelector("header");
        const nav = document.querySelector("nav");
        const navicon = document.querySelector(".nav-icon");
        const animationScroll = document.querySelector(".scroll-to-bottom");
        const main = document.querySelector(".main-homepage");
        const scrollTop = window.scrollY;
        if (scrollTop >= sidebarTop - 15) {
            header.classList.add("is-sticky");
            nav.classList.add("opacity-100");
            navicon.classList.add("display-nav-icon");
            animationScroll.classList.add("is-hidden");
            main.classList.add("opacity-100");
        } else {
            header.classList.remove("is-sticky");
            nav.classList.remove("opacity-100");
            nav.classList.remove("openMenu");
            navicon.classList.remove("display-nav-icon");
            animationScroll.classList.remove("is-hidden");
            main.classList.remove("opacity-100");
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    /*
    window.onscroll = function () {
        const stickyPositionActive =
            document.querySelector("header").offsetTop -
            (window.scrollY || document.documentElement.scrollTop);

        
    };
    */

    return (
        <header>
            <div
                className="nav-icon"
                onClick={() => dispatch(SetOpenMenu(!openMenu))}
            >
                {openMenu === false ? (
                    <>
                        <TfiMenuAlt />
                        <span>Menu</span>
                    </>
                ) : (
                    <>
                        <MdClose />
                        <span>Fermer</span>
                    </>
                )}
            </div>
            <div style={{ width: sidebarWidth }} className="navbar-brand">
                <span className="left">Les Trésors </span>
                <span className="right">de l'Harmonie</span>
            </div>
            <NavLink />
        </header>
    );
}
