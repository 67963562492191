import { useEffect } from "react";
import soinTenor from "../assets/img/tenor_handicap1.jpg";
import soinTenor2 from "../assets/img/soinTenor2.jpg";
import soinTenor3 from "../assets/img/soinTenor3.jpg";
import soinTenor4 from "../assets/img/soinTenor4.jpg";
import soinTenor5 from "../assets/img/soinTenor5.jpg";

import soinSabots from "../assets/img/soin-sabot.jpeg";
export default function PageSoinsAnimaux() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <section className="card-homepage">
                <div className="div_header">
                    <div className="div_header-title text-white">
                        <h1>Soins pour les Animaux</h1>
                        <p>
                            Parce que les Animaux sont des êtres vivants mais
                            qu'ils ne peuvent pas s'exprimer comme nous, il est
                            important de les écouter et de les comprendre.
                        </p>
                        <p>
                            C'est pourquoi je vous propose des soins adaptés à
                            vos animaux grâce à la communication animale et au
                            pendule.
                        </p>
                    </div>
                </div>
            </section>
            <section className="card-homepage_white">
                <div className="div_header-card-left">
                    <img src={soinSabots} alt="Soins Sabot Cheval" />
                </div>
                <div className="div_header-card-content">
                    <h2> Soins & Communication Animale :</h2>
                    <p>
                        Les soins apportés aux équidés, tels que les chevaux et
                        les ponyes, sont essentiels pour assurer leur bien-être,
                        leur santé et leur performance. Voici une explication
                        des principaux aspects des soins aux équidés :
                    </p>
                    <ul>
                        <li>
                            <span>Alimentation :</span>
                            <br />
                            Les équidés doivent recevoir une alimentation
                            équilibrée en fonction de leur âge, de leur poids,
                            de leur niveau d'activité et de leur état de santé.
                            Leur régime alimentaire doit inclure des fourrages
                            de qualité, tels que le foin, ainsi que des
                            concentrés (céréales, granulés) pour répondre à
                            leurs besoins nutritionnels spécifiques.
                        </li>
                        <li>
                            <span>Abreuvement :</span>
                            <br />
                            Les équidés doivent avoir accès à une eau propre en
                            quantité suffisante en tout temps. L'eau est
                            essentielle pour leur santé digestive, leur
                            température corporelle et leur bien-être général.
                        </li>
                        <li>
                            <span>Soins dentaires :</span>
                            <br />
                            Les équidés nécessitent des soins dentaires
                            réguliers pour éviter les problèmes dentaires tels
                            que les points de dents, les surdents, ou les dents
                            cassées, qui peuvent affecter leur alimentation et
                            leur confort.
                        </li>
                        <li>
                            <span>Soins des sabots :</span>
                            <br />
                            Les sabots des équidés doivent être entretenus
                            régulièrement. Cela inclut le parage pour maintenir
                            une bonne conformation et la prévention des maladies
                            comme la pourriture de la fourchette.
                        </li>
                        <li>
                            <span>Vaccinations et vermifugations :</span>
                            <br />
                            Les équidés nécessitent des vaccinations régulières
                            pour prévenir les maladies infectieuses. De plus, un
                            programme de vermifugation est essentiel pour
                            contrôler les parasites internes.
                        </li>
                        <li>
                            <span>Soins vétérinaires :</span>
                            <br />
                            Des examens vétérinaires réguliers sont importants
                            pour détecter et traiter les problèmes de santé le
                            plus tôt possible. Les vaccinations, les bilans
                            sanguins et d'autres interventions médicales font
                            partie des soins vétérinaires.
                        </li>
                        <li>
                            <span>Exercice et loisirs :</span>
                            <br />
                            Les équidés ont besoin d'exercice régulier pour
                            maintenir leur forme physique et mentale. L'accès à
                            un paddock ou à un pré, ainsi que des séances de
                            travail ou de loisirs, contribuent à leur bien-être.
                        </li>
                        <li>
                            <span>Hygiène :</span>
                            <br />
                            Le toilettage régulier, y compris le brossage, le
                            nettoyage des pieds et le soin de la crinière et de
                            la queue, contribue à maintenir la santé de la peau
                            et du pelage des équidés.
                        </li>
                        <li>
                            <span>Environnement :</span>
                            <br />
                            L'environnement dans lequel les équidés évoluent
                            doit être sûr, propre et adapté à leurs besoins
                            naturels.
                        </li>
                        <li>
                            <span>Surveillance du comportement :</span>
                            <br />
                            La surveillance régulière du comportement des
                            équidés permet de détecter rapidement tout signe de
                            maladie, de douleur ou de stress.
                        </li>
                    </ul>
                    <p>
                        En résumé, les soins aux équidés nécessitent une
                        approche holistique qui englobe leur alimentation, leur
                        environnement, leur santé physique et mentale. Une
                        attention constante à ces aspects contribue à assurer le
                        bien-être général des équidés.
                    </p>
                </div>
            </section>
            <section className="card-homepage_green">
                <div className="div_header-card-content">
                    <h2> Parce que le Bien-être animal est une priorité </h2>
                    <p className="text-center">
                        Voici l'évolution de Ténor, lama né avec les pieds
                        avants retournés.
                    </p>
                    <div className="img_tenor">
                        <img src={soinTenor} alt="Soin Ténor Lama Handicapé" />
                        <img src={soinTenor2} alt="Soin Ténor Lama Handicapé" />
                        <img src={soinTenor3} alt="Soin Ténor Lama Handicapé" />
                        <img src={soinTenor4} alt="Soin Ténor Lama Handicapé" />
                        <img src={soinTenor5} alt="Soin Ténor Lama Handicapé" />
                    </div>
                </div>
            </section>
            <section className="card-homepage_white">
                <div className="div_header-card-content text-center ">
                    <p className="p-5">
                        Tous les soins proposés ne se substituent en aucun cas à
                        une consultation auprès de d'un véterinaire ou d'un
                        professionnel de santé compétent et ne devant pas
                        interromptre un traitement médical en cours !
                    </p>
                </div>{" "}
            </section>
        </main>
    );
}
