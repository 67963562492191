import { useEffect } from "react";
import { SiWaze } from "react-icons/si";
import { ImAppleinc } from "react-icons/im";
import { FaGoogle } from "react-icons/fa";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { FaSms } from "react-icons/fa";
import carte_visite_les_tresors_de_lharmonie from "../assets/img/carte_visite_les_tresors_de_lharmonie.jpg";

export default function PageGPS() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <div className="card-homepage_other">
                <div className="card-title">
                    <h1>Contact & Lien GPS </h1>
                </div>
                <section className="card-homepage">
                    <div className="div_header">
                        <div className="div_header-card">
                            <img
                                src={carte_visite_les_tresors_de_lharmonie}
                                alt="Carte de Visite"
                            />
                        </div>
                        <div className="div_header-title">
                            <h2 className="text-center">
                                Les Trésors de l'Harmonie se situe à l'adresse :
                            </h2>
                            <div className="row">
                                <div className="btn-outline">
                                    <a href="tel:0649473160">
                                        Appeler <BsFillTelephoneOutboundFill />
                                    </a>
                                </div>{" "}
                                <div className="btn-outline">
                                    <a href="sms:0649473160">
                                        Envoyer <FaSms />
                                    </a>
                                </div>
                            </div>
                            <p className="text-center">
                                <small>Chemin du Hequeux</small>
                                <br />
                                <small>44770 La Plaine sur Mer</small> <br />
                            </p>

                            <h2>Lien GPS</h2>
                            <div className="listing-gps">
                                <div className="btn-outline">
                                    <a href="https://maps.apple.com/?address=Chemin%20du%20Hequeux,%2044770%20La%20Plaine-sur-Mer,%20France&ll=47.140314,-2.169571&q=Rep%C3%A8re%20sur%20le%20plan">
                                        <ImAppleinc /> Maps
                                    </a>
                                </div>
                                <div className="btn-outline">
                                    <a href="https://maps.app.goo.gl/k2wSuBh4zbFmckrD6">
                                        <FaGoogle />
                                        oogle Maps{" "}
                                    </a>
                                </div>
                                <div className="btn-outline">
                                    <a href="https://ul.waze.com/ul?ll=47.14097146%2C-2.16939211&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                                        <SiWaze /> Waze
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
}
