import { useEffect } from "react";
import { TiArrowDown } from "react-icons/ti";
import CardHomepageHumain from "../components/card/homepage-humain";
import CardHomepageHeader from "../components/card/homepage-header";
import CardHomepageAnimaux from "../components/card/homepage-animaux";

export default function HomePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleClickScrollToH1 = () => {
        setTimeout(() => {
            const element = document.getElementById("main");
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 200);
    };
    return (
        <>
            <div
                id="main"
                className="scroll-to-bottom "
                onClick={() => handleClickScrollToH1()}
            >
                <div>
                    <TiArrowDown />
                </div>
            </div>
            <main>
                <section className="main-homepage">
                    <CardHomepageHeader />
                    <div>
                        <CardHomepageHumain />
                        <CardHomepageAnimaux />
                    </div>
                </section>{" "}
            </main>
        </>
    );
}
