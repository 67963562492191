import { Link } from "react-router-dom";
import chouette from "../../assets/img/chouette.png";

export default function Footer() {
    return (
        <footer>
            <div className="column">
                <Link to="/cgv-mentions-legales">
                    Conditions Générales de Ventes
                </Link>
                <Link to="/cgv-mentions-legales">Mentions Légales</Link>
                <Link to="/contact-gps">Contact</Link>
            </div>

            <hr />
            <div className="footer text-center">
                <span className="">Les Trésors de l'Harmonie</span>
                <br /> Entreprise Individuelle TISSIER Marie-Hélène <br />
                N° de SIRET 521981878 00031 - APE 8690F et 6820B
            </div>
            <div className="name_litiboost">
                site réalisé par <img src={chouette} alt="Logo de LiTiBoost" />
                <span>Litiboost</span>{" "}
            </div>
        </footer>
    );
}
