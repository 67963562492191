import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SetOpenMenu } from "../../redux/actions/setupUser.actions";

export default function NavLink() {
    const dispatch = useDispatch();
    const openMenu = useSelector((state) => state.setupUser.openMenu);

    return (
        <nav
            className={openMenu ? "openMenu" : ""}
            onMouseLeave={() => dispatch(SetOpenMenu(false))}
        >
            <Link
                className="nav-link"
                onClick={() => dispatch(SetOpenMenu(false))}
                to="/"
            >
                Accueil
            </Link>
            <Link
                className="nav-link"
                onClick={() => dispatch(SetOpenMenu(false))}
                to="/soins/humains"
            >
                Soins Pour les Humains
            </Link>
            <Link
                className="nav-link"
                onClick={() => dispatch(SetOpenMenu(false))}
                to="/voyance-pendule"
            >
                Voyance & Pendule
            </Link>
            <Link
                className="nav-link"
                onClick={() => dispatch(SetOpenMenu(false))}
                to="/soins/animaux"
            >
                Soins Pour les Animaux
            </Link>
            <Link
                className="nav-link"
                onClick={() => dispatch(SetOpenMenu(false))}
                to="/pension-equides"
            >
                Pension Equidés
            </Link>
            <Link
                className="nav-link"
                onClick={() => dispatch(SetOpenMenu(false))}
                to="/contact-gps"
            >
                Contact & Accès Gps
            </Link>
        </nav>
    );
}
