import { useEffect } from "react";
import "../assets/css/video-react.css"; // import css
import img_video from "../assets/img/animaux.jpg";
import video from "../assets/img/animaux.mp4";
import { Player } from "video-react";
export default function PagePensionEquides() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <section className="card-homepage">
                <div className="div_header">
                    <div className="div_header-title text-white">
                        <h1>Hébergement Equidé</h1>
                        <p>
                            Votre cheval ou poney a besoin d'un endroit pour se
                            reposer, se nourrir et se dépenser ?
                        </p>
                        <p>
                            Découvrer l'hébergement que je propose pour vos
                            équidés.
                        </p>
                    </div>
                </div>
            </section>
            <section className="card-homepage_white">
                <div className="div_header-card-content">
                    <h2>Hébergement aux Prés</h2>
                    <p>
                        Pour le bien être de votre animal, je propose des prés
                        sécurisés (clos) pour qu'ils puissent se dépenser et se
                        nourrir en toute sécurité.
                    </p>
                    <hr />
                    <h2>Equipements</h2>

                    <ul>
                        <li>
                            Les animaux ne sont pas "ensembles" sans l'accord du
                            propriétaire
                        </li>
                        <li>Pré clos - Sécurisés</li>
                        <li>Abrevoirs Automatiques</li>
                        <li>
                            Herbes aux prés ou Foins (de notre propre production
                            ) en période hivernales
                        </li>
                        <li>
                            Carrière pour faire courir vos animaux ou la
                            pratique de cours (voir avec un professionnel
                            extérieur)
                        </li>
                        <li>Box à cheval pour entreposer vos affaires</li>
                        <li>
                            Box Poulinière dans le cas d'une immobilisation de
                            votre animal en cas de besoin (paille extérieure
                            utilisée en litière)
                        </li>
                    </ul>

                    <div>
                        <Player playsInline poster={img_video} src={video} />
                    </div>
                    <p className="text-center">
                        Nous sommes propriétaires de deux Chevaux, deux Shetland
                        ainsi que de deux Lamas.{" "}
                    </p>
                    <hr />
                    <h2>Contrat</h2>
                    <p className="text-center">
                        Contrat d'hébergement à signer entre les deux parties, à
                        périodicité mensuelle (par défaut) à tacite
                        reconduction.
                    </p>
                </div>
            </section>
        </main>
    );
}
