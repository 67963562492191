import { MdLocalPhone } from "react-icons/md";
import { Link } from "react-router-dom";

export default function WidgetPhone() {
    return (
        <div className="widget-phone">
            <Link to={"/contact-gps"}>
                <MdLocalPhone />
                Contact
            </Link>
        </div>
    );
}
