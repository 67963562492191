import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { FaSms } from "react-icons/fa";

export default function WidgetCGV() {
    return (
        <main>
            <div className="cvg">
                <h1 className="fs-1 text-center">
                    Conditions Générales de Ventes/Locations & Mentions Légales
                    <br />à compter du 1er Janvier 2024
                </h1>
                <p className="text-center fw-bold">
                    Les présentes conditions sont soumises à la loi française.
                </p>
                <section
                    id="sommaire"
                    className="mx-auto col-11 col-md-9 col-lg-6 "
                >
                    <h2 className="text-center fs-1">Sommaire</h2>
                    <ul>
                        <li>
                            <a href="#preambule">Préambule</a>
                        </li>
                        <li>
                            <a href="#art1">
                                Article 1 : Application des conditions générales
                                de vente.
                            </a>
                        </li>
                        <li>
                            <a href="#art2">Article 2 : Prestations</a>
                        </li>
                        <li>
                            <a href="#art3">Article 3 : Prestations et prix</a>
                        </li>
                        <li>
                            <a href="#art4">Article 4 : Devis</a>
                        </li>
                        <li>
                            <a href="#art5">Article 5 : Commande</a>
                        </li>
                        <li className="ms-3">
                            <a href="#art5.1">
                                Article 5.1 : Confirmation de la commande
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art5.2">
                                Article 5.2 : Acceptation de la prestation
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art5.2">
                                Article 5.3 : Modification de la prestation,
                                achat, et droit de rétractation.
                            </a>
                        </li>
                        <li>
                            <a href="#art6">Article 6 :Paiement</a>
                        </li>
                        <li className="ms-3">
                            <a href="#art6.1">
                                Article 6.1 : Modalités de paiement
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art6.2">
                                Article 6.2 : Retard et défaut de paiement
                            </a>
                        </li>

                        <li>
                            <a href="#art7">Article 7 : Responsabilités</a>
                        </li>

                        <li>
                            <a href="#art8">
                                Article 8 : Clause de confidentialité
                            </a>
                        </li>
                        <li>
                            <a href="#art9">
                                Article 9 : Traitement des données personnelles
                            </a>
                        </li>
                        <li>
                            <a href="#art10">
                                Article 10 : Propriété intellectuelle & Droits à
                                l’image & Protection des données
                            </a>
                        </li>
                        <li>
                            <a href="#art11">Article 11 : Cookies</a>
                        </li>
                        <li>
                            <a href="#art12">Article 12 : Modifications</a>
                        </li>

                        <li>
                            <a href="#art13">
                                Article 13 : Loi applicable au contrat{" "}
                            </a>
                        </li>
                        <li>
                            <a href="#art14">Article 14 : Litige</a>
                        </li>
                        <li>
                            <a href="#art15">
                                Article 15 : Coordonnées du conciliateur
                            </a>
                        </li>
                    </ul>
                </section>
                <hr />
                <section className="pt-5" id="preambule">
                    <h2 className="fs-1">Préambule</h2>
                    <p>
                        L’exploitant individuel TISSIER Marie-hélène exerçant
                        sous le nom commercial « Les Trésors de l'Harmonie »
                        dont le siège social est situé : 0 Chemin du Héqueux
                        44770 La Plaine sur Mer, immatriculées au Répertoire des
                        métiers de Nantes, sous le N° de SIRET 521981878 00031 -
                        APE 8690F et 6820B
                    </p>

                    <p>
                        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin
                        2004 pour la confiance dans l'économie numérique, il est
                        précisé aux utilisateurs du site internet
                        www.lestresorsdelharmonie.fr l'identité des différents
                        intervenants dans le cadre de sa réalisation et de son
                        suivi : Propriétaire - Responsable publication & Délégué
                        à la protection des données: TISSIER Marie-hélène -
                        0649473160 - Le responsable publication est une personne
                        physique.
                    </p>
                    <p>
                        A compter du 01 Janvier 2024, le présent site est
                        hébergé par l'Entreprise Individuelle TISSIER Lionel -
                        litiboost.fr - sous le nom commercial "LiTiBoost".
                        "LiTiBoost" est une marque déposée auprès de l'INPI.
                        <br />
                        Le serveur est situé en France chez : <br />
                        OVH
                        <br />
                        SAS au capital de 10 174 560 €<br />
                        RCS Lille Métropole 424 761 419 00045
                        <br />
                        Code APE 2620Z
                        <br />
                        N° TVA : FR 22 424 761 419
                        <br />
                        Siège social : 2 rue Kellermann - 59100 Roubaix - France
                        <br />
                        OVH SAS est une filiale de la société OVH Groupe SA,
                        société immatriculée au RCS de Lille sous le numéro 537
                        407 926 sise 2, rue Kellermann, 59100 Roubaix.
                        <br />
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art1">
                    <h2 className="fs-1">
                        Article 1: Application des conditions générales de
                        vente.
                    </h2>
                    <p>
                        Les présentes Conditions Générales de Vente sont
                        accessibles sur les sites internet :
                        www.lestresorsdelharmonie.fr et sont systématiquement
                        transmises à chaque client s’il en fait la demande.
                    </p>
                    <p>
                        En conséquence toute commande effectuée par le client,
                        toute commande de services, produits, demande de devis,
                        mise en place de contrat d'hébergement d'équidé qu’il
                        soit un particulier ou une entreprise emporte son
                        adhésion, sans restriction, ni réserve aux présentes
                        Conditions Générales de Vente, lesquelles prévalent sur
                        tout autre document émis par l’exploitant individuel
                        TISSIER Marie-hélène
                    </p>
                    <p>
                        Toute autre condition imposée par le client sera
                        irrecevable aux conditions générales de vente, quel que
                        soit le moment où elle aura pu être portée à la
                        connaissance de l’exploitant individuel TISSIER
                        Marie-hélène sans accord au préalable entre les deux
                        parties.
                    </p>
                    <p>
                        Si l’exploitant individuel TISSIER Marie-hélène ne
                        respecte pas l’une des quelconques règles présentes aux
                        Conditions Générales de Vente, cette tolérance ne peut
                        être interprétée comme valant renonciation aux dites
                        conditions.
                    </p>
                    <p>
                        Le client, qu’il soit un particulier ou une entreprise,
                        déclare avoir pris connaissance et accepté les présentes
                        Conditions Générales de Vente avant de passer toute
                        commande de services, produit, demande de devis, mise en
                        place de contrat d'accompagnement. Les présentes
                        Conditions Générales de Vente sont valables à compter du
                        1er Janvier 2024 et s’appliquent jusqu’à ce qu’une
                        nouvelle version les remplace.
                    </p>
                </section>{" "}
                <hr />
                <section className="pt-5" id="art2">
                    <h2 className="fs-1">Article 2 : Prestations</h2>
                    <p>
                        Les services (soins) et l'hébergement d'équidé proposés
                        par l’exploitant individuel TISSIER Marie-hélène se
                        produisent normalement du Lundi au Samedi de 9h00 à
                        18h00 - Sur Rendez-vous uniquement. Néanmoins sur accord
                        par les parties, l’exploitant individuel TISSIER
                        Marie-hélène peut offrir d’autres créneaux de
                        disponibilité, notamment le soir (à partir de 22H) pour
                        des soins à distance uniquement (sans appel
                        téléphonique, sms uniquement).
                    </p>
                    <p>
                        L’exploitant individuel TISSIER Marie-hélène propose des
                        prestations de soins en présentiel ou à distance, sur
                        humains ou animaux, ainsi que l'hébergement d'équidé sur
                        ses propres prés (contrat d'hébergement). Dans certaines
                        situations une tarification particulière pourra être
                        appliquée selon le service demandé ainsi que sa
                        quantité. Pour toute demande complémentaire, une étude
                        sera produite et un devis sera proposé au client
                        (concernant les hébergements d'équidé).
                    </p>
                </section>{" "}
                <hr />
                <section className="pt-5" id="art3">
                    <h2 className="fs-1">Article 3 : Prestations et prix</h2>
                    <p>
                        Les services et produits faisant l’objet des CGV/CGU
                        sont ceux qui figurent sur le site et la brochure et qui
                        sont proposés directement par l’exploitant individuel
                        Hennion Fabien exerçant sous le nom commercial « SER1 »
                        et l’enseigne : « Ma maison sereine » ou ses
                        prestataires partenaires.
                    </p>
                    <p>
                        Les services proposés et produits sont décrits au sein
                        du site et brochures avec toutes leurs caractéristiques
                        essentielles. L’exploitant individuel Hennion Fabien
                        exerçant sous le nom commercial « SER1 » et l’enseigne :
                        « Ma maison sereine » ne peut être tenu responsable de
                        l’impossibilité d’effectuer une prestation ou vente de
                        produits quand le client n’est pas éligible aux offres.
                    </p>
                    <p>
                        Les offres indiquées sur le site et brochures sous le
                        nom d’enseigne ma maison sereine
                        (www.mamaisonsereine.fr) concerne uniquement les
                        particuliers.
                    </p>
                    <p>
                        Lorsqu’un utilisateur souhaite obtenir une prestation de
                        service, produit, vendue par l’exploitant individuel
                        Hennion Fabien exerçant sous le nom commercial « SER1 »
                        et l’enseigne : « Ma maison sereine » à travers le site
                        et brochure, le prix indiqué de la prestation ou produit
                        correspond au prix en euros et tient compte des
                        réductions applicables et en vigueur le jour de la
                        commande.
                    </p>
                    <p>
                        L’exploitant individuel Hennion Fabien exerçant sous le
                        nom commercial « SER1 » et l’enseigne : « Ma maison
                        sereine » se réserve la possibilité de modifier à tout
                        moment ses tarifs.
                    </p>
                    <p>
                        Pour les prestations et produits dont le prix n’est pas
                        défini avec exactitude un devis ou contrat sera proposé
                        aux clients. En aucun cas un client ne pourra exiger
                        l’application de réductions n’étant plus en vigueur le
                        jour de la commande.
                    </p>
                </section>{" "}
                <hr />
                <section className="pt-5" id="art4">
                    <h2 className="fs-1">Article 4 : Devis</h2>
                    <p>
                        L’exploitant individuel TISSIER Marie-Hélène remet
                        gratuitement un devis au client qui en fait la demande.
                    </p>
                </section>{" "}
                <hr />
                <section className="pt-5" id="art5">
                    <h2 className="fs-1">Article 5 : Commande</h2>
                    <p>
                        Toute commande de prestations ou de demande de
                        renseignement peut se faire par :
                    </p>
                    <p>
                        Les prospects et clients ne peuvent être reçu sur place
                        hors prise de rendez-vous.
                    </p>
                    <ul>
                        <li>Téléphone : 06 49 47 31 60</li>
                        <div className="row">
                            <div className="btn-outline">
                                <a href="tel:0649473160">
                                    Appeler <BsFillTelephoneOutboundFill />
                                </a>
                            </div>{" "}
                            <div className="btn-outline">
                                <a href="sms:0649473160">
                                    Envoyer <FaSms />
                                </a>
                            </div>
                        </div>
                        <li>
                            Courrier : TISSIER Marie-Hélène - 0 Chemin du
                            Héqueux - La Plaine sur Mer.
                        </li>
                    </ul>
                    <p>
                        L’exploitant individuel TISSIER Marie-Hélène se réserve
                        le droit de refuser toute demande de services qu’il
                        jugerait dangereuse, illicite ou contraire à l’ordre
                        public et aux bonnes mœurs de la société ou contraire au
                        bien-être de la personne/animal.
                    </p>

                    <p>
                        Pour toute commande, le client devra communiquer son
                        nom/prénom et numéro de téléphone.
                    </p>
                    <p>
                        L’exploitant individuel TISSIER Marie-Hélène s’engage à
                        répondre à la demande du client dans les meilleurs
                        délais, par SMS ou téléphone.
                    </p>
                </section>
                <section className="pt-5" id="art5.1">
                    <h2 className="fs-1">
                        Article 5.1 : Confirmation de la commande
                    </h2>
                    <p>
                        L’acceptation de la commande au client par l’exploitant
                        individuel TISSIER Marie-Hélène par l’envoi d’un devis /
                        d’un contrat d'hébergement (hébergement d'équidé) ou
                        d'un accord oral entre les deux partie (prestations de
                        services).
                    </p>
                </section>
                <section className="pt-5" id="art5.2">
                    <h2 className="fs-1">
                        Article 5.2 : Acceptation de la prestation
                    </h2>
                    <p>
                        Le client devra impérativement confirmer l’acceptation
                        de sa commande de prestation d'hébergement en acceptant
                        le devis/contrat par écrit sous 7 jours, et s’acquitter
                        du règlement intégral de la prestation, service, produit
                        avant que l’exploitant individuel TISSIER Marie-Hélène
                        ne démarre la réalisation de la prestation ou la mise à
                        disposition de l'hébergement commandé. Suite à
                        l’acceptation écrite du devis par le client, toute
                        prestation et achat de produit sera due.
                    </p>
                </section>
                <section className="pt-5" id="art5.3">
                    <h2 className="fs-1">
                        Article 5.3 : Modification de la prestation, achat, et
                        droit de rétractation.
                    </h2>
                    <p>
                        Le contrat d'hébergement de l'équidé est conclue pour
                        une durée indéterminée en tacite reconduction d'un mois.
                        Chacune des partie pourra rompre la convention à tout
                        moment sans avoir à justifier sa rupture en adressant un
                        courrier recommandé avec accusé de réception ou remis en
                        main propre contre signature en double exemplaire. Dans
                        ce cas, le propriétaire devra en informer l'hébergeur
                        dans un délai de 15 jours minimal avant le départ de
                        l'équidé.
                    </p>
                    <p>
                        Tout mois consommé (entamé) est dû en totalité par le
                        propriétaire.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art6">
                    <h2 className="fs-1">Article 6 : Le Paiement</h2>
                </section>
                <section className="pt-5" id="art6.1">
                    <h2 className="fs-1">
                        Article 6.1 : Modalités de paiement
                    </h2>
                    <p>
                        Les services seront facturés sur la base des tarifs
                        précisément indiqués, en euros, sur le devis/contrat ou
                        grille tarifaire fournit par l’exploitant individuel
                        TISSIER Marie-Hélène. Les prestations devront être
                        réglées intégralement, en espèces, par chèque, par carte
                        bancaire (majoration possible) ou par virement bancaire.
                    </p>
                </section>
                <section className="pt-5" id="art6.2">
                    <h2 className="fs-1">
                        Article 6.2 : Retard et défaut de paiement
                    </h2>
                    <p>
                        En cas de retard de paiement par le bénéficiaire, les
                        prestations/contrats en cours pourront être
                        interrompues, sans mise en demeure préalable ni
                        préjudice de toute indemnité et/ou voie d’action.
                    </p>
                    <p>
                        Toute somme non payée à son échéance portera de plein
                        droit, et sans mise en demeure préalable, des pénalités
                        de retard de 10% tous les 10 jours de retard, ainsi que
                        l’application d’intérêts de retard au taux légal.
                    </p>
                    <p>
                        Le défaut de paiement d’une facture à son échéance
                        autorise l’exploitant individuel TISSIER Marie-Hélène à
                        annuler les commandes en cours, sans mise en demeure
                        préalable ni préjudice de toute indemnité et/ou voie
                        d’action et sans préjudice du recouvrement des sommes
                        restant dues par le bénéficiaire.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art7">
                    <h2 className="fs-1">
                        Article 7 : Responsabilités / Assurances{" "}
                    </h2>
                    <p>
                        Le propriétaire de l'équide reconnait avoir visité les
                        lieux au préalable de l'exécution du contrat et les
                        agréer dans l'état dans lequel les lieux se trouvent.
                        Les prés et les infrastructures n'étant pas sous
                        surveillance de l'hébergeur ainsi le propriétaire
                        décharge l'hébergeur de toute responsabilité en cas
                        d'accident ou de vol. Le propriétaire a la liberté de
                        longer entre le pré de l'équidé et le box (réservé au
                        préalable), une mise en liberté n'est possible
                        uniquement dans le pré dédié à l'équidé ainsi que dans
                        la carrière. Dans le cas où l'équidé s'échappe de son
                        pré pour quelque raison que se soit (ex : portail mal
                        fermé par le propriétaire, saut de l'animal au delà des
                        clôtures, etc), les dégradations engendrées seront
                        facturées au propriétaire dans le cadre de sa
                        responsabilité civile. L'hébergeur prend à sa charge
                        l'assurance des risques de responsabilité civile
                        découlant de la garde de l'équidé au pré/box. De même,
                        le propriétaire doit avoir souscrit une responsabilité
                        civile de son côté et doit fournir son attestation
                        d'assurance à l'hébergeur pour l'exécution du contrat.
                        Le propriétaire prend à sa charge les risques de
                        "mortalité" de son équidé. Il est entendu que le
                        propriétaire renonce à tout recourt contre l'hébergeur
                        dans l'hypothèse d'accident survenu à l'équidé et
                        n'engageant pas la responsabilité professionnelle de
                        l'hébergeur. La Liste des personnes autorisées par le
                        propriétaire à utiliser l'équidé sera annexé au présent
                        contrat. Ces personnes devront être titulaires d'une
                        assurance individuelle couvrant les risques liés à
                        l'équitation et devront fournir leur attestation
                        d'assurance à l'hébergeur.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art8">
                    <h2 className="fs-1">
                        Article 8 : Clause de confidentialité
                    </h2>
                    <p>
                        L’exploitant individuel TISSIER Marie-Hélène respectera
                        une clause de confidentialité concernant toutes les
                        prestations de services réalisées.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art9">
                    <h2 className="fs-1">
                        Article 9 : Traitement des données personnelles
                    </h2>
                    <p>
                        Ce traitement des données à caractère personnel se fait
                        dans le respect du Règlement Général sur la Protection
                        des Données 2016/679 du 27 avril 2016.
                    </p>
                    <p>
                        Par ailleurs, conformément à la loi Informatique et
                        Libertés du 6 janvier 1978, le client dispose, à tout
                        moment, d'un droit d'interrogation, d'accès, de
                        rectification, de modification et d'opposition à
                        l'ensemble de ses données personnelles. En écrivant, par
                        courrier et en justifiant de son identité, à l'adresse
                        suivante :TISSIER Marie-Hélène - 0 Chemin du Hequeux -
                        44770 La-Plaine-sur-Mer. Ces données personnelles sont
                        nécessaires au traitement de sa commande et à
                        l'établissement de ses factures le cas échéant, ainsi
                        qu'à l'amélioration des fonctionnalités du site.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art10">
                    <h2 className="fs-1">
                        Article 10 : Propriété intellectuelle & Droits à l’image
                        & Protection des données
                    </h2>
                    <p>
                        Le logo de "Les Trésors de l'Harmonie" et du présent
                        site - lestresorsdelharmonie.fr - a été réalisé par M
                        TISSIER Lionel entreprise individuelle "Litiboost" -
                        <a
                            href="https://litiboost.fr"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://litiboost.fr
                        </a>{" "}
                        - ainsi que le fond d'écran utilisé (background). Toute
                        diffusion, exploitation, représentation, reproduction,
                        qu’elle soit partielle ou intégrale sans l’autorisation
                        expresse de litiboost exposera le contrevenant à des
                        poursuites civiles et pénales. Les images utilisées sur
                        le site sont libres de droits et originaires du site
                        pixabay.fr
                    </p>
                    <p>
                        Concernant la Protection des données, ’exploitant
                        individuel TISSIER Marie-Hélène assure un niveau de
                        sécurité approprié et proportionnel aux risques encourus
                        ainsi qu'à leur probabilité, conformément au Règlement
                        Général sur la Protection des Données 2016/679 du 27
                        avril 2016. Toutefois, ces mesures ne constituent en
                        aucun cas une garantie et n'engagent pas l’exploitant
                        individuel TISSIER Marie-Hélène à une obligation de
                        résultat concernant la sécurité des données.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art11">
                    <h2 className="fs-1">Article 11 : Cookies</h2>
                    <p>
                        Pour permettre à ses utilisateurs de bénéficier d’une
                        navigation optimale sur le site et d’un meilleur
                        fonctionnement des différentes interfaces et
                        applications, la société est susceptible d’implanter un
                        cookie sur l’ordinateur de l’utilisateur. Ce cookie
                        permet de stocker des informations relatives à la
                        navigation sur le site, ainsi qu’aux éventuelles données
                        saisies par les utilisateurs (notamment recherches,
                        login, email, mot de passe).
                    </p>
                    <p>
                        L’utilisateur autorise expressément la Société à déposer
                        sur le disque dur de l’utilisateur un fichier dit «
                        cookie ». L’utilisateur dispose de la possibilité de
                        bloquer, modifier la durée de conservation, ou supprimer
                        ce cookie via l’interface de son navigateur. Si la
                        désactivation systématique des cookies sur le navigateur
                        de l’utilisateur l’empêche d’utiliser certains services
                        ou fonctionnalités du site, ce dysfonctionnement ne
                        saurait en aucun cas constituer un dommage pour le
                        membre qui ne pourra prétendre à aucune indemnité de ce
                        fait.
                    </p>
                    <p>Le présent site ne comporte pas de cookie</p>
                </section>
                <hr />
                <section className="pt-5" id="art12">
                    <h2 className="fs-1">Article 12 : Modifications</h2>
                    <p>
                        L’exploitant individuel TISSIER Marie-Hélène se réserve
                        le droit de modifier le site, les services qui y sont
                        proposés, les CGV/CGU ainsi que toute procédure de
                        livraison ou autre élément constitutif des prestations
                        réalisées par l’exploitant individuel TISSIER
                        Marie-Hélène par le biais du site. Lorsqu'il passe une
                        commande, l'utilisateur est soumis aux stipulations
                        énoncées par les CGV/CGU en vigueur lors de la passation
                        de la commande.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art12">
                    <h2 className="fs-1">
                        Article 12 : Loi applicable au contrat
                    </h2>
                    <p>
                        L’exploitant individuel TISSIER Marie-Hélène est une
                        auto-entreprise de droit français. La loi applicable aux
                        relations contractuelles est la loi française
                        exclusivement.
                    </p>
                </section>
                <hr />
                <section className="pt-5" id="art13">
                    <h2 className="fs-1">Article 13 : Litige</h2>
                    <p>
                        Tout litige relatif à l’interprétation et à l’exécution
                        des conditions générales de services est soumis au droit
                        français. A défaut de résolution amiable, le litige sera
                        porté devant les tribunaux civils compétents.
                    </p>
                </section>
                <section className="pt-5" id="art14">
                    <h2 className="fs-1">
                        Article 14 : Coordonnées du conciliateur
                    </h2>
                    <p>
                        Conciliateur du Ministère de la Justice Permanence sur
                        rendez-vous auprès de la Pornic Agglo Pays de Retz{" "}
                        <br />2 Rue du Dr Ange Guépin
                        <br />
                        44210 Pornic <br />
                        Téléphone : 02 51 74 07 16 <br />
                        Télécopie : 02 51 74 10 10
                    </p>
                </section>
            </div>
        </main>
    );
}
