import imgPre from "../../assets/img/pre.jpeg";

export default function CardHomepageHeader() {
    return (
        <section className="card-homepage-header">
            <div className="div_header">
                <div className="div_header-title">
                    <h1>
                        Prenez soin de votre corps, de votre âme et de vos
                        animaux
                    </h1>
                    <small>
                        Partez à la découverte de soins qui vise à rétablir
                        l'équilibre énergétique du corps, de l'âme et de
                        l'esprit.
                    </small>
                    <small>
                        Ayez une autre vision de vos soucis pour mieux les
                        comprendre et les résoudre.
                    </small>
                </div>
                <div className="div_header-card">
                    <img src={imgPre} alt="Pré dans notre propriété" />
                </div>
            </div>
        </section>
    );
}
