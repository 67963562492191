import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import Homepage from "../pages/homepage";
import HeaderNav from "../components/header/header";
import Footer from "../components/footer/footer";
import CGV from "../pages/CGV";
import PageGPS from "../pages/GPS";
import HeaderOtherPage from "../components/header/headerOtherPage";
import PageSoinsHumains from "../pages/SoinsHumains";
import PageSoinsAnimaux from "../pages/SoinsAnimaux";
import PagePendules from "../pages/Pendules";
import PagePensionEquides from "../pages/PensionEquides";
import WidgetPhone from "../components/widget/phone";

export default function Router() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route>
                    <Route
                        index
                        path="/"
                        element={
                            <>
                                <HeaderNav />
                                <Homepage />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/soins/humains"
                        element={
                            <>
                                <HeaderOtherPage />
                                <PageSoinsHumains />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/soins/animaux"
                        element={
                            <>
                                <HeaderOtherPage />
                                <PageSoinsAnimaux />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/voyance-pendule"
                        element={
                            <>
                                <HeaderOtherPage />
                                <PagePendules />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/pension-equides"
                        element={
                            <>
                                <HeaderOtherPage />
                                <PagePensionEquides />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/cgv-mentions-legales"
                        element={
                            <>
                                <HeaderOtherPage />
                                <CGV />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="/contact-gps"
                        element={
                            <>
                                <HeaderOtherPage />
                                <PageGPS />
                                <Footer />
                            </>
                        }
                    ></Route>

                    <Route
                        path="*"
                        element={
                            <>
                                <HeaderOtherPage />
                                <Homepage />
                                <WidgetPhone />
                                <Footer />
                            </>
                        }
                        status={404}
                    ></Route>
                </Route>
            </>
        )
    );
    return router;
}
