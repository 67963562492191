import React from "react";
import ReactDOM from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./utils/sass/index.scss";
import rootReducer from "./redux/reducers";
import Router from "./router/router.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = Router();
const store = configureStore({
    reducer: rootReducer,
    devTools: false,
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />{" "}
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
