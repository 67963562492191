import { useEffect } from "react";
import soins from "../assets/img/massage-therapy-1612308_640.jpg";
import tired from "../assets/img/tired-8104379_640.jpg";
import toy from "../assets/img/toy-6022744_640.jpg";
export default function PageSoinsHumains() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <section className="card-homepage">
                <div className="div_header">
                    <div className="div_header-title">
                        <h1>Soins pour les Humains</h1>
                        <small>
                            Partez à la découverte de soins qui vise à rétablir
                            l'équilibre énergétique du corps, de l'âme et de
                            l'esprit.
                        </small>
                        <small>
                            Ayez une autre vision de vos soucis pour mieux les
                            comprendre et les résoudre.
                        </small>
                    </div>
                    <div className="div_header-card">
                        <img src={soins} alt="Massage thérapeutique" />
                    </div>
                </div>
            </section>
            <section className="card-homepage_white">
                <div className="div_header-card-left">
                    <img src={toy} alt="Marionnette en bois" />
                </div>
                <div className="div_header-card-content">
                    <h2>Domaines d'application Corporel :</h2>
                    <p>
                        Que l'on soit Bébé, Adulte ou Agés, à tout âge notre
                        corps peut ressentir le besoins de soins pour aller
                        mieux suite aux aléas de la vie. Le corps nous parle via
                        des signaux extérieurs ou intérieurs, il est important
                        de l'écouter et de lui apporter les soins nécessaires.
                    </p>
                    <ul>
                        <li>
                            <span>Douleurs musculaires et articulaires : </span>
                            <br />
                            Soulager vos douleurs liées à des tensions
                            musculaires, des entorses, des lumbagos, ou d'autres
                            problèmes similaires.
                        </li>

                        <li>
                            <span> Problèmes circulatoires :</span> <br />
                            La reboutologie peut favoriser une meilleure
                            circulation sanguine ou favoriser le drainage
                            lymphatique.
                        </li>
                        <li>
                            <span> Problèmes de peau :</span> <br />
                            Brûlures, eczéma, psoriasis, Zona, acné, coup de
                            soleil, etc. peuvent être soulagés et réduits grâce
                            au magnétisme.
                        </li>
                    </ul>
                </div>
            </section>
            <section className="card-homepage_green">
                <div className="div_header-card-content">
                    <h2>Domaines d'application Psychique :</h2>
                    <p>
                        Parce que tous nos actes sont liés à nos émotions et
                        peuvent avoir des conséquences sur notre corps physique
                        et mental, il est important de prendre soin de notre
                        esprit, remonter notre niveau d'énergie en soi.
                    </p>
                    <ul>
                        <li>
                            <span>Stress :</span> <br />
                            Soulager le stress, l'anxiété, les angoisses, les
                            troubles du sommeil, les dépressions, etc.
                        </li>
                        <li>
                            <span>Problèmes/Blocages émotionnels :</span> <br />
                            Surmonter vos problèmes émotionnels, comme des
                            chocs, des deuils, des traumatismes, etc. La
                            pratique de la TLE (technique de libération
                            émotionnel) est conseillée. D'autre technique
                            énergétique peuvent agir également sur l'énergie
                            vitale du corps, contribuant ainsi à rétablir
                            l'harmonie générale.
                        </li>
                    </ul>
                </div>
                <div className="div_header-card-right">
                    <img src={tired} alt="Personne fatiguée et stressée" />
                </div>
            </section>
            <section className="card-homepage_white">
                <div className="div_header-card-content text-center ">
                    <p className="p-5">
                        Tous les soins proposés ne se substituent en aucun cas à
                        une consultation médicale auprès de votre médecin
                        traitant/spécialiste et ne devant pas interromptre un
                        traitement médical en cours sans l'avis de votre médecin
                        !
                    </p>
                </div>
            </section>
        </main>
    );
}
