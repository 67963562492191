import imgPre from "../../assets/img/IMG_4534.jpg";

export default function CardHomepageAnimaux() {
    return (
        <>
            <section className="card-homepage-humain">
                <h2>Pour les Animaux</h2>
                <hr />
                <h3>Soins :</h3>
                <ul>
                    <li>
                        <span>Communication Animale et soins : </span>
                        <br />
                        Les animaux utilisent divers moyens de communication
                        pour exprimer leurs besoins, émotions, intentions ou
                        pour établir des liens sociaux.
                    </li>
                    <li>
                        <span>Phytothérapie :</span> <br />
                        La phytothérapie peut être utilisée pour traiter une
                        variété de conditions de santé, en traitant non
                        seulement les symptômes, mais aussi en cherchant à
                        équilibrer le corps dans son ensemble.
                    </li>
                </ul>
                <h3>Pensions d'équidés:</h3>
                <ul>
                    <li>
                        <span>Enclos / Prés sécurisés</span>
                    </li>
                    <li>
                        <span>Carrière</span>
                    </li>
                    <li>
                        <span>Box</span>
                    </li>
                </ul>
            </section>
            <div className="div_header">
                <div className="div_header-card">
                    <img src={imgPre} alt="Pré" />
                </div>
            </div>
        </>
    );
}
